<template>


  <div style="background-color:#eeee">
    <div class="hidden-md-and-down search_component" row wrap style="height:170px">

      <v-parallax src="/assets/material.jpg" style="height:200px"></v-parallax>


    </div>



    <div style="background-color:#fff">
      <v-container>
        <br>

        <v-layout row wrap>

          <v-flex xs12 md4 sm4 class="hj" pr-5 pt-2>


           <v-autocomplete :items="items_serch" v-model="serch_key"   item-text="item_name" :hide-no-data="false"
      hide-details hide-selected 
            item-value="item_name"  placeholder="اكتب اسم المركز" @change="search_title()" >
            </v-autocomplete>


          </v-flex>
         

             <v-flex xs12 md3 sm3 pr-5 class="hj hidden-lg-and-up">

   

              <treeselect :multiple="true" search-nested :options="options"  :auto-load-root-options="false"
              v-model="subs_cats" placeholder=" اختار القسم" />
          </v-flex>


          <v-flex xs12 md3 sm3 pr-6 class="hidden-lg-and-up hj">
            <!-- <p class="lap_ser">المحافظه</p> -->
            <treeselect :options="options_provinces" :flat="true" v-model="searches.provinces_id"
              :auto-load-root-options="false" :multiple="true" placeholder="اختر المحافظة" height="30px" required />
          </v-flex>

          <v-row style="margin-right:2%" class="hidden-lg-and-up">

                  <v-radio-group v-model="orderby" @change="orderbyfun" :mandatory="false">
                    <v-radio label=" العناصر  المضافة مؤخرا" value="-id"></v-radio>
                    <v-radio label=" العناصر الاقرب " value="distance"></v-radio>

                  </v-radio-group>

                </v-row>


  

          <v-flex xs12 md3 sm3 class="hidden-lg-and-up" pr-4>

            <v-btn class="ma-2" tile outlined color="#ff6600" width="200" height="50" pt-4 @click="search_funs()">
              <v-icon right>fas fa-search</v-icon> البحث
            </v-btn>
          </v-flex>

        </v-layout>



      </v-container>

    </div>




    <div>
    
      <v-container>
          <v-row justify="center">

          <v-flex xs12 xl2 md3 sm4 pl-3 v-show="!loading" class="hidden-md-and-down">
            <div>
              <v-card style="padding-top:30px;padding-bottom:20px">
                <p style="font-weight:bold;padding-right:10px">الاقسام</p>

                <v-divider class="mb-6"></v-divider>
                <v-treeview :items="categores" v-model="subs_cats" item-children="sub_category" selectable
                  style="padding-right:20px" hoverable rounded>

                </v-treeview>


                <v-divider class="mb-3"></v-divider>
                <div class="hidden-md-and-down lap_ser" style="padding:13px;">المحافظه</div>
                <div style="padding:13px;">

                  <treeselect :options="options_provinces" :flat="true" v-model="searches.provinces_id"
                    :auto-load-root-options="false" :multiple="true" placeholder="اختر المحافظة" height="30px"
                    required />
                </div>


                <v-divider class="mb-3"></v-divider>

                <v-row style="margin-right:2%">

                  <v-radio-group v-model="orderby" @change="orderbyfun" :mandatory="false">
                    <v-radio label=" العناصر  المضافة مؤخرا" value="-id"></v-radio>
                    <v-radio label=" العناصر الاقرب " value="distance"></v-radio>

                  </v-radio-group>

                </v-row>
                <v-divider class="mb-3"></v-divider>

                <br>
                <v-btn class="ma-2" tile outlined color="#ff6600" width="200" height="50" pt-4 @click="search_funs()">
                  <v-icon right>fas fa-search</v-icon> البحث
                </v-btn>

              </v-card>

            </div>

          </v-flex>

          <v-flex xs12 xl8 md9 sm9 v-show="loading">
            <v-skeleton-loader ref="skeleton" type="article" class="mx-auto"></v-skeleton-loader>
            <v-skeleton-loader v-for="a in 6" :key="a" ref="skeleton" type="list-item-two-line" class="mx-auto">
            </v-skeleton-loader>

          </v-flex>

          <!-- this is partion of items -->

          <v-flex xs12 xl8 md9 sm9 v-if="!loading">


            <cardItem :items="items">
            </cardItem>




            <v-layout row wrap>
              <v-row justify="center" pa-5 style="margin-top:20px">

                <label class="mybooking-title" v-show="error_msg">
                  <!-- <v-icon color="warning"> fas fa-exclamation-triangle</v-icon> -->
                  <!-- لا توجد حجوزات -->
                </label>
                <v-btn color="gray" @click="getMore_page" v-show="getmore">تحميل المزيد </v-btn>
                <v-progress-circular indeterminate color="primary" v-show="loading"></v-progress-circular>
              </v-row>
            </v-layout>

          </v-flex>

          <v-flex v-else xs12 xl8 md9 sm9 pa-3>
            <v-flex xs12 sm12 md12 style="width:100%;padding-bottom:2%" v-for="a in 3" :key="a">
              <v-card>
                <v-skeleton-loader ref="skeleton" type="article" class="mx-auto"></v-skeleton-loader>
                <v-skeleton-loader ref="skeleton" type="list-item-two-line" class="mx-auto"></v-skeleton-loader>
              </v-card>
            </v-flex>


          </v-flex>

        </v-row>
      </v-container>



        <v-layout>


        </v-layout>
     
    </div>
  </div>


</template>
<script>
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import cardItem from './sub_components/items_card'
 


  const axios = require('axios');

  export default {
    data() {
      return {
        searchs_title: '',
        search: [{
          sub_cat_id: null,
        }],
        serch_key:'',
        id: 0,
        subs_cats: [],
        urL: 'http://109.224.27.9:81',
        items: [],
        getmore: false,
        loading: false,
        orderby: '-id',

        value: [],

        current_page: 1,
        last_page: 0,
        options: [],
        xx: null,
        provinces: [],
        searches: {
          subs_cat: [],
          orderby: '-id',
          provinces_id: [],
          title: ''
        },
        categores: [],
        search_history: this.$cookies.get('search_history'),
        provinces_id: null,
        items_serch_titel: [],
        error_msg: false,
        selected_sub_cat: [],
        optionss: null,
        options_provinces: [],
        numof_subcat_selected: -1,
        urlNow: '',
        orderbyselected: '',
        long: 0,
        lat: 0
      }
    },
    mounted() {

      this.getitemsByCat_id();
      this.getCategory();
      this.Get_All_items_title();
      this.getProvinces();
      this.id = this.$route.params.id;
    },


    methods: {

        search_title() {
     //   alert(this.serch_key);
        this.$http.get("/v2/items/search?filter[item_name]=" +this.serch_key+ "&sort=-id").then(response => {
        this.items = response.data.data;
        })

      },

      // search_title(title) {
      //   this.$http.get("/v2/items/search?filter[item_name]=" + title + "&sort=-id").then(response => {
      //   this.items = response.data.data;
      //   })

      // },

      search_select(input_search) {
        this.search_fun(this.search.sub_cat_id, this.provinces_id, input_search);
      },

      search_funs() {
       

        if (this.subs_cats.length >= 1) {
          this.search_fun(this.subs_cats, this.searches.provinces_id, this.searches.title, this.searches.orderby)

        }


      },


      orderbyfun(orderby) {

        this.searches.orderby = orderby;

        if (this.subs_cats.length == 1) {
        //  this.getitemsByCat_id();
        } else {
         // this.search_fun(this.subs_cats, 'all', this.searches.title, this.searches.orderby)
        }

      },
      getitemsByCat_id() {

        this.$http.get("/v2/items/search?filter[Getoffers]=1&sort=-id&page=1").then(response => {
        this.items = response.data.data;


        })
      },

      searchs_history(sub_Cat_id) {
        for (var i = 0; i < sub_Cat_id.length; i++) {
              this.search_history.sub_cats_id.push(parseInt(sub_Cat_id));
              this.$cookies.set('search_history', this.search_history)
        }
      },


      search_fun(sub_Cat_id, provinces_id, title, orderby) {
        
        if (title == 'no' || title == undefined || title == null || title == 'null') {
          title = ''
        }
        if (sub_Cat_id == 'no' || sub_Cat_id == 'all')  {
          sub_Cat_id = ''
        }
        if (provinces_id == 'all' || provinces_id == null) {
          provinces_id = ''
        }

       


        this.getmore = false;
        this.error_msg = false
        this.loading = true
        let self = this;

        navigator.geolocation.getCurrentPosition(function (position) {
        position
        navigator.geolocation.getCurrentPosition(position => {
        self.long = position.coords.latitude;
        self.lat = position.coords.longitude;

          });
          if (orderby == 'distance') {

            orderby = "distance"
            self.long = this.long
            self.lat = this.long
          } else {
            self.orderby = "-id"
            self.long = '';
            self.lat = '';
          }



        }, function () {
          orderby = "-id"
          self.long = '';
          self.lat = '';
          alert('يرجى تفعيل الوصول للموقع');
        });


         this.searchs_history(sub_Cat_id);






        axios({
            method: 'get',
            url: "/v2/items/search?filter[sub_category.id]=" + sub_Cat_id + "&filter[address.province.id]=" +
              provinces_id + "&filter[item_name]=" + title + "&sort=" + self.orderby,
            headers: {
              long: self.long,
              lat: self.lat
            }
            //headers: header
          })
          .then(response => {


            self.items = response.data.data;
            self.current_page = response.data.meta.current_page;
            self.last_page = response.data.meta.last_page;

            //hidden getmore button if there is no more
            if (self.current_page == self.last_page) {
              self.getmore = false;
            } else {
              self.getmore = true
            }
            if (self.items.length < 1) {
              self.error_msg = true
            }


          })
          .catch(
            self.error_msg = true

          ).finally(
            self.loading = false
          );
        //  });

      },



      setlocation(lat, lng) {

        this.getNearestItems(lat, lng);
      },





      filter(input) {

        if (input.length < 1) {
          this.search.text_titel = null;
          return []
        }
        return this.items_serch_titel.filter(item => {

          this.search.text_titel = input;
          return item.toLowerCase()
            .startsWith(input.toLowerCase())
        })
      },


      getCategory() {

        this.$http.get("/v2/Categories").then(response => {
          this.categores = response.data.data;


          for (var i = 0; i <= this.categores.length; i++) {
            this.categores[i].id = i + 100;
            this.sub_cat = this.categores[i].sub_category.map(item => {
              return {
                id: item.id,
                label: item.name,

              };
            });


            //Push Categories in TreeSelect
            this.options.push({
                id: 'x' + this.categores[i].id,
                label: this.categores[i].name,
                children: this.sub_cat

                //    this.sub_cat


              }

            );



          }


        });


      },



      Get_All_items_title() {

        var url = "/v2/items/getAllItemsTitle";
        this.$http.get(url).then(response => {
          this.items_serch = response.data;

          // var i = 0;

          // for (i = 0; i < this.items_serch.length; i++) {

          //   this.items_serch_titel.push(this.items_serch[i].item_name);

          // }


        }).catch(error => {
          error

        });


      },

      getProvinces() {


        var url = "/v2/info/Provinces";
        axios.get(url).then(response => {
          this.provinces = response.data.data;
          var i = 0;
          for (i = 0; i <= this.provinces.length; i++) {
            var provin = this.provinces[i];
            this.options_provinces.push({
              id: provin.id,
              label: provin.province_name,
            });


          }


        });

      },



      getMore_page() {
        this.loading = true;
        if (this.current_page == this.last_page) {
          this.getmore = false;
          this.loading = false;
        } else {


          this.current_page = this.current_page + 1;
          //   this.search_fun(this.search.sub_cat_id, this.search.provinces_id, this.search.text_titel)




        }

      },



    },
    created() {


      // this.search_fun(this.$route.params.cat_id, this.$route.params.gov_id, this.$route.params.tit,orderby='-id');

    },



    computed: {



    },



    watch: {

      selected: 'search by sub_cat_id',
       '$route': function () {
      this.$http.get("/v2/items/search?filter[Getoffers]=1&sort=-id&page=1").then(response => {
      this.items = response.data.data;
        })

      },
    },
    components: {
      Treeselect,
      cardItem,



    }
  };
</script>